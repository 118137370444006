/**
 * Cấu hình khóa Local Storage cho lưu trữ dữ liệu.
 * Yêu cầu:
 *  - Cần đặt tên khóa đúng cú pháp để tránh sự trùng lặp tên khóa.
 *  - Cần viết mô tả cho khóa đã khai báo:
 *    vd: // Khóa lưu trữ <Nội dung thông tin> trong chức năng <Tên chức năng>
 *  - Cần quy hoạch khóa dựa theo chức năng (feature) hoặc toàn ứng dung (global):
 *  - Xem mẫu ở dưới nhé!!!
 * */
export const LocalStorageConfigKeys = {
  global: {
    transloco: {
      // Khóa lưu trữ ngôn ngữ hiện tại của Transloco.
      currentLanguage: 'global.transloco.currentLangulage',
    },
    routing: {
      // Khóa lưu trữ URL trước đó trong định tuyến sau khi login thành công.
      previousUrl: 'global.routing.previousUrl',
      devPreviousUrl: 'global.routing.devPreviousUrl',
    },
    appKey: `global.appKey`,
    guideUserOnBoarding: 'globa.guideUserOnBoarding',
  },
  feature: {
    auth: {
      //  Khóa lưu trữ thông tin người dùng trong chức năng xác thực.
      userInformation: 'feature.auth.user',
      //  Khóa lưu trữ mã thông báo truy cập trong chức năng xác thực.
      accessToken: 'feature.auth.token',
      // Khóa lưu trữ danh sách các ID hành động trong chức năng xác thực.
      actionIds: 'feature.auth.actionIds',

      loginErrorCount: `feature.auth.loginErrorCount`,

      // Khóa lưu trữ các bước xác thực tài khoản khi đăng nhập tài khoản lần đầu
      // Ví dụ: Sau khi đăng nhập sẽ có các bước xác thực email, số điện thoại, đổi password lần dầu...
      firstLoginVerify: `feature.auth.firstLoginVerify`,
      firstLoginVerifyError: `feature.auth.firstLoginVerifyError`,
    },

    ho_so_ca_nhan: {
      // Thông tin những thuộc tính cần cập nhật tài khoản
      update_profile_requirement:
        'feature.auth.ho_so_ca_nhan.update_profile_requirement',
      error_requirement: 'feature.auth.ho_so_ca_nhan.error_requirement',
    },
    layouts: {
      tabSetting: {
        themeMode: 'feature.layouts.tabSetting.themeMode',
      },
      themeSetting: 'feature.layouts.themeSetting',
    },
  },
};
