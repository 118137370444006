import { environment } from '@config/environments/environment';

// Each project have diffrent appId - you only need to change appId at this const.
export const APP_ID_FOR_PROJECT = 8;

export const LINK_NAVIGATE_TO_POLICY_WHEN_REGISTER = '';

// Scope login google
export const GOOGLE_LOGIN_SCOPES = ['email', 'profile'];

// 5 lần login failure -> enable capcha
export const LOGIN_COUNT_ERROR_LIMIT_TO_ENABLE_CAPCHA = 5;
