export const environment = {
  production: false,
  domain: {
    apiAuth: 'https://texttospeech.api.ttithutech.com/api',
    puiPui: 'https://texttospeech.api.ttithutech.com/api',
  },
  developer: {
    username: 'NNV0050588',
    password: '123qwe@..',
  },
  storage: {
    cookies: {
      expireInSeconds: 3600, // 1 hour
    },
  },
  application: {
    appKey: '',
    appId: 0,
    http: {
      // http request timeout
      timeout: 30 * 1000, // 30 giây
    },
    ui: {
      // <-- Nên chuyển thành biến nội bộ
      pagingation: {
        pageSizeDefault: 10, // Số mục trên mỗi trang
        pageSizeOptions: [10, 20, 50, 100, 200], // Tùy chọn kích thước trang,
      },
    },
    reCapchaSecretKey: '6LcRH0EUAAAAADIgNOVAUuaYVRNi6HfExT3sdr2F',
    googleAuthClientID:
      '886168325284-hd9f5k6s30htjvgmibcs6mh745c3r27a.apps.googleusercontent.com',
  },
};
