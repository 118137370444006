// TODO: Dùng khi muốn offline mà vẫn thấy dữ liệu, lưu js đồ được.

export const CacheStorageConfigKeys = {
  global: {
    files: {
      html: {

      },
      icon: {

      },
      js: {

      }
    }
  }
}
