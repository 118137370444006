import { environment } from './environments/environment';

export const API_VERSIONs = {
  VERSION_1_0_0: '1.0.0',
  VERSION_2_0_0: '2.0.0',
};

export const API_ENDPOINTS = {
  authen: `${environment.domain.apiAuth}/authen`, // <<-- API AUTH

  //#region other
  convertImageToText: `${environment.domain.puiPui}/image-to-text`,
  //#region
};
